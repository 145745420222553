<template>
  <div class="pa-3">
    <input
      type="file"
      id="files"
      ref="files"
      class="excel-upload-input"
      multiple
      v-on:change="select_file"
      :accept="accept"
      v-if="showDetail"
    />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <span v-if="editedItem.id < 0">
              <v-checkbox
                v-model="editedItem.isurl"
                label="Url "
                :readonly="readonly"
                dense
              ></v-checkbox>
            </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.ref"
                    :readonly="readonly"
                    label="Reference"
                    required
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.description"
                    :readonly="readonly"
                    label="Description"
                    :rules="[(v) => !!v || 'Description required']"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  v-if="editedItem.id < 0 && !editedItem.isurl"
                >
                  <v-btn
                    color="blue darken-1"
                    @click="addFiles()"
                    v-if="!readonly"
                  >
                    <v-icon> mdi-paperclip </v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="editedItem.isurl">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model="editedItem.filename"
                    :readonly="readonly"
                    label="Url"
                    @input="validateUrl"
                    :rules="[
                      (v) => !!v || 'Url required',
                      (v) => !v || isValid || 'invalide Url',
                    ]"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="editedItem.id < 0 && !editedItem.isurl"
                >
                  <v-chip
                    v-for="file in chosenFiles"
                    :key="file.name"
                    close
                    @click:close="delchips(file)"
                  >
                    {{ file.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <span v-if="sendingfile"> sending : {{ sendingfile }} </span>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="upload()"
              v-if="!readonly && editedItem.id < 0 && !editedItem.isurl"
            >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save()"
              v-if="!readonly && (editedItem.id > 0 || editedItem.isurl)"
            >
              Save
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import UPLOAD from "../graphql/UPLOAD.gql";
import UPDATE_DOC from "../graphql/File/UPDATE_DOC.gql";
import CREATE_DOC from "../graphql/File/CREATE_DOC.gql";
import JSZip from "jszip";

export default {
  components: {},
  name: "fileform",
  props: {
    item: Object,
    readonly: Boolean,
    showForm: Boolean,
    extensions: Array,
    well_id: Number,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    chosenFiles: [],
    filesize: 0,
    kf: 100,
    url: {},
    accept: "",
    isValid: false,
    sendingfile: null,
  }),

  computed: {
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "New Document "
          : "Document : ".concat(this.item.filename);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) this.editedItem = Object.assign({}, this.item);
    if (this.extensions)
      this.extensions.forEach((element) => {
        if (this.accept == "") this.accept = "." + element.ext;
        else this.accept = this.accept + ",." + element.ext;
      });
  },

  methods: {
    validateUrl() {
      const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      this.isValid = pattern.test(this.editedItem.filename);
    },
    async up_file(content) {
      this.editedItem.doc_type = this.item.doc_type;
      this.editedItem.c_size = content.size / 1000;
      this.editedItem.create_uid = this.$store.state.me.id;
      await this.$apollo
        .mutate({
          mutation: UPLOAD,

          variables: {
            file: content,
            description: this.editedItem.description,
            ref: this.editedItem.ref,
            filename: this.editedItem.filename,
            size: this.editedItem.size,
            well_id: this.well_id,
            c_size: this.editedItem.c_size,
            doc_id: this.editedItem.doc_id,
            doc_type: this.editedItem.doc_type,
            auth: this.editedItem.auth,
            create_uid: this.$store.state.me.id,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          let d = JSON.parse(data.data.Upload);
          this.editedItem.id = d.id;
          this.editedItem.url = d.url;
          this.editedItem.doc_date = d.doc_date;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar = true;
        });
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > 0) {
          await this.maj(UPDATE_DOC, {
            doc: {
              id: this.editedItem.id,
              description: this.editedItem.description,
              filename: this.editedItem.filename,
              well_id: this.well_id,
              isurl: this.editedItem.isurl ? 1 : 0,
              ref: this.editedItem.ref,
              doc_id: this.item.doc_id,
              doc_type: this.item.doc_type,
              auth: this.item.auth,
              write_uid: this.$store.state.me.id,
            },
          });
        } else {
          await this.maj(CREATE_DOC, {
            doc: {
              description: this.editedItem.description,
              filename: this.editedItem.filename,
              isurl: this.editedItem.isurl ? 1 : 0,
              well_id: this.well_id,
              ref: this.editedItem.ref,
              doc_id: this.item.doc_id,
              doc_type: this.item.doc_type,
              auth: this.item.auth,
              create_uid: this.$store.state.me.id,
            },
          });
        }
        this.$emit("changed");
        this.close();
      }
    },
    async upload() {
      if (this.$refs.form.validate()) {
        if (this.chosenFiles) {
          for (let index = 0; index < this.chosenFiles.length; index++) {
            const element = this.chosenFiles[index];
            if (!this.progress && element.size > 0) {
              this.editedItem.filename = element.name;
              this.sendingfile = element.name;
              this.editedItem.size = element.size / 1000;
              this.progress = true;
              var zip = new JSZip();
              zip.file(element.name, element);
              let content = await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: { level: 9 },
              });
              await this.up_file(content);
              this.sendingfile = null;
              this.progress = false;
            }
          }
          this.$nextTick(() => {
            this.$emit("changed");
            this.close();
          });
        }
      }
    },

    select_file() {
      if (this.$refs.files.files) {
        let ok = true;
        for (let index = 0; index < this.$refs.files.files.length; index++) {
          const element = this.$refs.files.files[index];
          if (
            element.size >
            this.$store.state.options[0].max_file_size * 1000000
          ) {
            this.snackbar_text =
              "Unable to add file " +
              element.name +
              " ! File size greater than " +
              this.$store.state.options[0].max_file_size +
              " Mb";
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
            break;
          }
        }
        if (ok) {
          this.chosenFiles = this.$refs.files.files;
          this.kf++;
        }
      }
    },
    delchips(item) {
      let i = this.chosenFiles.findIndex((elm) => elm.name == item.name);
      if (i >= 0) {
        this.chosenFiles.splice(i, 1);
      }
    },
    addFiles() {
      this.$refs.files.click();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
</style>
